import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../components/Header";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import theImage from "../assets/images/university/heigher-ed.png";
import usaImage from "../assets/images/countries/statue-liberty-foreground.jpg";
import ukImage from "../assets/images/countries/panoramic-view-big-ben-london-sunset-uk.jpg";
import ausImage from "../assets/images/countries/sydney-opera-house-is-lit-up-evening-sun.jpg";
import canadaImage from "../assets/images/countries/toronto-skyline-sunset-with-cn-tower.jpg";
import nzImage from "../assets/images/countries/auckland-city-panorama-new-zealand-auckland-commercial-hub-major-travel-port-new-zealand-otherwise-known-as-city-sails-this-panorama-auckland.jpg";
import switzerlandImage from "../assets/images/countries/cityscape-night-zurich-switzerland.jpg";
import germanyImage from "../assets/images/countries/annobon-iamazing-berlin-germanysland-guinea.jpg";
import sampleUniversity from "../assets/images/countries/11.jpg";
import qsImage from "../assets/images/university/qs.png";
import ratingImage from "../assets/images/review/rating.png";
import { TailSpin } from 'react-loader-spinner';
import quotes from "../assets/images/review/quotes.png";
import parse from 'html-react-parser';
import Slider from "react-slick";
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faGlobe, faClock } from '@fortawesome/free-solid-svg-icons';
import { faClock as faClockRegular } from '@fortawesome/free-regular-svg-icons';
import StarRatings from 'react-star-ratings';
import ShowMoreText from "react-show-more-text";
import groupPhoto from "../assets/images/about-us/group-university-photo.jpg";



const apiUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    cssEase: 'linear',
    arrows: false,
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

const Universities = () => {
    const { name } = useParams();
    const navigate = useNavigate();
    const [loadingPage, setLoadingPage] = useState(true);
    const [email, setEmail] = useState("");
    const [backgroundImage, setBackgroundImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [university, setUniversity] = useState({});
    const [rating, setRating] = useState(0); // State for star rating
    const [reviewText, setReviewText] = useState(""); // State for review text

    const executeOnClick = (isExpanded) => {
        console.log(isExpanded);
    }

    let universityName = name.replace(/-/g, ' ');
    universityName = universityName.split(' ').map(word =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const handleClick = () => {
        if(localStorage.getItem('token')){
            let reviewData = {
                "country_of_study":university.country_name,
                "institute_name":university.name,
                "city_of_study":university.city_name
            };
            localStorage.setItem("signupFormValues",JSON.stringify(reviewData));
            navigate('/review'); 
        }else{
            navigate('/login'); 
        }
    };

    const handleReviewChange = (e) => {
        setReviewText(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}submit-review`, {
                universityName,
                reviewText,
                rating
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            toast.success('Review submitted successfully!');
            setReviewText("");
            setRating(0);
        } catch (error) {
            console.error('Error submitting review:', error);
            toast.error('Failed to submit review.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        axios.get(`${apiUrl}university?university_name=${universityName}`)
            .then(response => {
                const data = response.data;
                setUniversity(data);
                setLoadingPage(false);
                setReviews(data.reviews || []);
                switch (data.country_name) {
                    case "United States":
                        setBackgroundImage(usaImage);
                        break;
                    case "United Kingdom":
                        setBackgroundImage(ukImage);
                        break;
                    case "Australia":
                        setBackgroundImage(ausImage);
                        break;
                    default:
                        setBackgroundImage(usaImage); // Default image if country is not matched
                }
            })
            .catch(error => console.error('Error fetching university data:', error));
    }, [universityName]);

    
    // Function to format the 'about_us' text
    const formatAboutUsText = (text) => {
        if (!text) return '';
        return text
            .replace(/\\/g, '')           
            .replace(/\n/g, ' ')             // Replace newline characters with spaces
            // .replace(/\. /g, '.<br />');     // Add break after periods
    };
    


    return (
        <>
            <Header />

            {loadingPage ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <TailSpin 
                        height="80" 
                        width="80" 
                        color="#4fa94d" 
                        ariaLabel="loading"
                    />
                </div>
            ) : (
                <>

                <section className="pt-4 pb-4">
                    <div className="container-fluid">
                        <div className="row mx-0 w-100 justify-content-center">
                            <div className="col-lg-11">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><Link to="/home" className="fw-500">HOME</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{name.toUpperCase()}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-padding pt-0">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-11 position-relative">
                                <div
                                    className="inner-banner position-relative"
                                    style={{ backgroundImage: `url(${backgroundImage})` }}
                                >
                                </div>
                                <div className="d-flex align-items-center university-name">
                                    <div className="university-img-inner">
                                        <img src={university.photo == null ? sampleUniversity : university.photo } alt="" className="img-fluid" />
                                    </div>
                                    <h1 className="text-white ms-4">{university.name}</h1>
                                </div>
                                <div className="university-rank">
                                    <div className="card mb-3 p-2">
                                        <div className="row g-0 align-items-center">
                                            <div className="col-lg-4 col-4">
                                                <img src={theImage} alt="" className="img-fluid" />
                                            </div>
                                            <div className="col-lg-8 col-8">
                                                <div className="card-body">
                                                    <h5 className="card-title">THE Ranking:- <span className="text-green">{university.the_ranking}</span></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3 p-2">
                                        <div className="row g-0 align-items-center">
                                            <div className="col-lg-4 col-4">
                                                <img src={qsImage} alt="" className="img-fluid" />
                                            </div>
                                            <div className="col-lg-8 col-8">
                                                <div className="card-body">
                                                    <h5 className="card-title">QS Ranking:- <span className="text-green">{university.qs_ranking_year}</span></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-12">
                                <div className="d-flex justify-content-between text-center row">
                                    <div className="university-ratio position-relative col-sm-4 col-4">
                                        <div className="value">
                                            {university.the_stats_student_staff_ratio}
                                        </div>
                                        <div className="number-text">
                                            Student Staff Ratio
                                        </div>
                                    </div>
                                    <div className="university-ratio position-relative col-sm-4 col-4">
                                        <div className="value">
                                            {university.the_stats_pc_intl_students}
                                        </div>
                                        <div className="number-text">
                                            International Student
                                        </div>
                                    </div>
                                    <div className="university-ratio position-relative col-sm-4 col-4">
                                        <div className="value">
                                            {university.the_stats_female_male_ratio}
                                        </div>
                                        <div className="number-text">
                                            Gender Ratio(M:F:O)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-11 border-bottom">
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-5">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-11">
                                <div className="row">
                                    <div className="col-lg-8 justify-content-start">
                                        <ShowMoreText
                    lines={5}
                    more="Show more"
                    less="Show less"
                    anchorClass="show-more-less-clickable show-more-less-text-color"
                    onClick={executeOnClick}
                    expanded={false}
                    width={800}
                    truncatedEndingComponent={"... "}
                >
                    {parse(formatAboutUsText(parse(university.about_us)))}
                    </ShowMoreText>
                                            
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="uni-contact-details">
                                            <div className="d-flex mb-3">
                                                <span><FontAwesomeIcon icon={faLocationDot} /></span>
                                                <p className="ms-3">{university.formatted_address}</p>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <span><FontAwesomeIcon icon={faPhone} /></span>
                                                <p className="ms-3">
                                                    <a href={`tel:${university.formatted_phone_number}`}>{university.formatted_phone_number}</a>
                                                </p>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <span><FontAwesomeIcon icon={faGlobe} /></span>
                                                <p className="ms-3">
                                                    <a href={university.website} target="_blank" rel="noopener noreferrer">{university.website}</a>
                                                </p>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <span><FontAwesomeIcon icon={faClockRegular} /></span>
                                                <p className="ms-3">07:00 AM To 07:00 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-padding position-relative bg-gray">
                    <div className="sec-head mb-5 text-center">
                        University Reviews
                    </div>
                    <div id="uni-review">
                        <Slider {...sliderSettings}>
                            {reviews.map((review, index) => (
                                <div className="card bg-white reviews-card position-relative h-100" key={index}>
                                    <div className="card-body">
                                        <div className="d-flex mb-4 position-relative">
                                            <div className="me-3">
                                                <img src={review.profile_photo_url} alt={review.author_name} width="50" height="50" className="user-img" />
                                            </div>
                                            <div>
                                                <h4>{review.name}</h4>
                                                <h5 className="rubik">{review.author_name}</h5>
                                            </div>
                                            <div className="review-quotes">
                                                <img src={quotes} alt="Quotes" width="50" height="50" />
                                            </div>
                                        </div>
                                            <ShowMoreText
                                                lines={3}
                                                more="Show more"
                                                less="Show less"
                                                className="content-css card-text"
                                                anchorClass="show-more-less-clickable show-more-less-text-color"
                                                onClick={executeOnClick}
                                                expanded={false}
                                            width={340}
                                                truncatedEndingComponent={"... "}
                                            >
                                                {review.text}
                                            </ShowMoreText>
                                        <img src={ratingImage} alt="Rating" className="img-fluid" />
                                        <br /> 
                                        <br /> 
                                        <span><p className='google-review-font'>Google Review</p></span>
                                        {/* <img alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiNGRkMxMDciIGQ9Ik00My42MTEsMjAuMDgzSDQyVjIwSDI0djhoMTEuMzAzYy0xLjY0OSw0LjY1Ny02LjA4LDgtMTEuMzAzLDhjLTYuNjI3LDAtMTItNS4zNzMtMTItMTJjMC02LjYyNyw1LjM3My0xMiwxMi0xMmMzLjA1OSwwLDUuODQyLDEuMTU0LDcuOTYxLDMuMDM5bDUuNjU3LTUuNjU3QzM0LjA0Niw2LjA1MywyOS4yNjgsNCwyNCw0QzEyLjk1NSw0LDQsMTIuOTU1LDQsMjRjMCwxMS4wNDUsOC45NTUsMjAsMjAsMjBjMTEuMDQ1LDAsMjAtOC45NTUsMjAtMjBDNDQsMjIuNjU5LDQzLjg2MiwyMS4zNSw0My42MTEsMjAuMDgzeiI+PC9wYXRoPjxwYXRoIGZpbGw9IiNGRjNEMDAiIGQ9Ik02LjMwNiwxNC42OTFsNi41NzEsNC44MTlDMTQuNjU1LDE1LjEwOCwxOC45NjEsMTIsMjQsMTJjMy4wNTksMCw1Ljg0MiwxLjE1NCw3Ljk2MSwzLjAzOWw1LjY1Ny01LjY1N0MzNC4wNDYsNi4wNTMsMjkuMjY4LDQsMjQsNEMxNi4zMTgsNCw5LjY1Niw4LjMzNyw2LjMwNiwxNC42OTF6Ij48L3BhdGg+PHBhdGggZmlsbD0iIzRDQUY1MCIgZD0iTTI0LDQ0YzUuMTY2LDAsOS44Ni0xLjk3NywxMy40MDktNS4xOTJsLTYuMTktNS4yMzhDMjkuMjExLDM1LjA5MSwyNi43MTUsMzYsMjQsMzZjLTUuMjAyLDAtOS42MTktMy4zMTctMTEuMjgzLTcuOTQ2bC02LjUyMiw1LjAyNUM5LjUwNSwzOS41NTYsMTYuMjI3LDQ0LDI0LDQ0eiI+PC9wYXRoPjxwYXRoIGZpbGw9IiMxOTc2RDIiIGQ9Ik00My42MTEsMjAuMDgzSDQyVjIwSDI0djhoMTEuMzAzYy0wLjc5MiwyLjIzNy0yLjIzMSw0LjE2Ni00LjA4Nyw1LjU3MWMwLjAwMS0wLjAwMSwwLjAwMi0wLjAwMSwwLjAwMy0wLjAwMmw2LjE5LDUuMjM4QzM2Ljk3MSwzOS4yMDUsNDQsMzQsNDQsMjRDNDQsMjIuNjU5LDQzLjg2MiwyMS4zNSw0My42MTEsMjAuMDgzeiI+PC9wYXRoPgo8L3N2Zz4="/> */}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </section>

                <section className="section-padding">
                    <ToastContainer position="top-center" />
                    <div className="container">
                    
                            

                            
                        <div className="row justify-content-center">
                        <div className='col-sm-6'>
                                <img src={groupPhoto} className="img-fluid" ></img>
                            </div>
                            <div className="col-sm-6 mt-5">
                                <center>
                                    <h1><b>Your voice matters!</b></h1>
                                </center>
                                <p>
                                Share your university experience and empower others to make the best decision for their future
                                Be part of a community that values transparency and authenticity.
                                </p>
                                  <br/>
                                <center>

                                        <button type="button" className="btn btn-primary w-40" onClick={handleClick}>
                                        Review Your University Now
                                        </button>
                                </center>

                                    <div className="card-heading"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )}
    </>
    );
};

export default Universities;
