import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";
import "../assets/css/style.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";

const apiUrl = process.env.REACT_APP_API_URL;

const OTP = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [isRetryOTP, setIsRetryOTP] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(3); // Assuming initial value
  const [message, setMessage] = useState("");
  const inputsRef = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/home", {
        replace: true,
      });
    }
  }, [navigate]);

  useEffect(() => {
    // Load remaining attempts from localStorage
    const attempts =
      parseInt(localStorage.getItem("remainingAttempts"), 10) || 3;
    setRemainingAttempts(attempts);
  }, []);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus the next input box
    if (element.value !== "" && index < 5) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "") {
        if (index > 0) {
          inputsRef.current[index - 1].focus();
        }
      } else {
        setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const data = e.clipboardData.getData("text/plain").split("");
    const newOtp = otp.map((_, index) => data[index] || "");

    setOtp(newOtp);
    newOtp.forEach((value, index) => {
      if (value !== "" && index < 5) {
        inputsRef.current[index + 1].focus();
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = otp.join("");
    let signupData = JSON.parse(localStorage.getItem("signupData"));
    let email = signupData.email;

    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUrl}login`, {
        otp: otpCode,
        email,
      });
      localStorage.setItem("isLogin", true);
      localStorage.setItem("token", response.data.data.access_token);
      localStorage.setItem("email", response.data.data.email);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("campaign_id", response.data.data.campaign_id);
      localStorage.setItem("is_show_referal", response.data.data.show_referal);
      navigate("/home", {
        replace: true,
      });

      window.location.reload();
    } catch (error) {
      setMessage("Verification failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const resendOTP = async () => {
    if (remainingAttempts <= 0) {
      toast.error("No remaining attempts. Please try again later.");
      return;
    }

    setIsRetryOTP(true);
    let signupData = JSON.parse(localStorage.getItem("signupData"));
    let email = signupData.email;

    try {
      const response = await axios.post(`${apiUrl}sendotp`, { email });

      if (response.data.status) {
        toast.success(
          "Your OTP has been successfully resent to your email. Please check your inbox."
        );
        setRemainingAttempts(remainingAttempts - 1);
        localStorage.setItem("remainingAttempts", remainingAttempts - 1);
        localStorage.setItem("lastAttemptTime", Date.now());
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while sending OTP. Please try again.");
    } finally {
      setIsRetryOTP(false);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <section className="section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-5">
              <div className="login-form otp-form">
                <h1>Enter OTP</h1>
                <form onSubmit={handleSubmit} onPaste={handlePaste}>
                  <div className="otp-inputs d-flex justify-content-between mb-4">
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        className="otp-input form-control"
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputsRef.current[index] = el)}
                      />
                    ))}
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary w-100 mb-2"
                    onClick={resendOTP}
                    disabled={isRetryOTP}
                  >
                    {isRetryOTP ? (
                      <ThreeDots height={50} width={50} />
                    ) : (
                      "Resend OTP"
                    )}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Verifying...
                      </>
                    ) : (
                      "Verify OTP"
                    )}
                  </button>
                </form>
                {message && <p className="text-danger mt-2">{message}</p>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OTP;
