import React, { useEffect, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import './Modal.css';  // Custom CSS for modal styling
import usaImage from "../assets/images/svg/usa.svg";
import ukImage from "../assets/images/svg/uk.svg";
import germanyImage from "../assets/images/svg/germany.svg";
import irelandImage from "../assets/images/svg/ireland.svg";
import australiaImage from "../assets/images/svg/aus.svg";
import canadaImage from "../assets/images/svg/canada.svg";
import nzImage from "../assets/images/svg/nz.svg";
import spainImage from "../assets/images/svg/spain.svg";
import franceImage from "../assets/images/svg/france.svg";
import singaporeImage from "../assets/images/svg/singapore.svg";
import sampleUniversity from "../assets/images/countries/university_15395078.png";


const Charts = () => {
  const [countryData, setCountryData] = useState([]);
  const [chartReady, setChartReady] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // Modal visibility state
  const [modalContent, setModalContent] = useState(""); // Content inside modal

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}map-data`);
        const data = await response.json();

        const formattedData = Object.keys(data).map((countryKey) => {
          const countryInfo = data[countryKey];
          return {
            id: getCountryCode(countryKey),
            name: getCountryName(countryKey),
            value: countryInfo.count,
            color: getColorByCountry(countryKey),
            universities: countryInfo.university,
            courses: countryInfo.courses,
            rating: countryInfo.university_ranking,
            flag: getFlagUrlByCountry(countryKey) // Add flag URL here
          };
        });
        setCountryData(formattedData);
        setChartReady(true);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    fetchCountryData();
  }, []);

  useEffect(() => {
    if (!chartReady) return;

    let root = am5.Root.new("chartdiv");
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {})
    );

    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ["AQ"]
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      fill: am5.color("#8dacf7"),
      stroke: am5.color("#8dacf7")
    });

    let bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        valueField: "value",
        calculateAggregates: true,
        polygonIdField: "id"
      })
    );

    bubbleSeries.bullets.push((root, series, dataItem) => {
      let container = am5.Container.new(root, {});

      let picture = container.children.push(
        am5.Picture.new(root, {
          src: dataItem.dataContext.flag, // Use the flag URL here
          width: 24,
          height: 24,
          tooltipText: `Click for details ${dataItem.dataContext.name}`, // Tooltip on hover
          cursorOverStyle: "pointer",
          draggable: false
        })
      );

      // Add hover effect
      picture.states.create("hover", {
        scale: 1.2, // Scale up on hover
        stroke: am5.color("#ffffff"),
        strokeWidth: 2,
      });

      // Show modal on click
      picture.events.on("click", (ev) => {
        const modalHTML = getModalHTML(dataItem.dataContext.universities, dataItem.dataContext.courses, dataItem.dataContext.rating);
        showModal(modalHTML);  // Display the modal
      });

      return am5.Bullet.new(root, {
        sprite: container,
        dynamic: true
      });
    });

    bubbleSeries.data.setAll(countryData);

    return () => {
      root.dispose();
    };
  }, [chartReady, countryData]);

  const showModal = (html) => {
    setModalContent(html);
    setModalVisible(true);  // Show modal
  };

  const hideModal = () => {
    setModalVisible(false);  // Hide modal
  };

  const getModalHTML = (universities, courses, rating) => {
    let html = `
      <div style='padding: 10px;'>
        <table style='width: 100%; border-collapse: collapse;'>
          <thead>
            <tr>
              <th colspan="2" style='padding: 10px; text-align: left; color: #333; font-size: 16px; background-color: #f2f2f2;'>
                Top Ranked Universities
              </th>
            </tr>
            <tr>
              <th style='padding: 10px; text-align: left; color: #333; background-color: #e9e9e9; border: 1px solid #ddd; font-size: 12px;'>QS Ranking</th>
              <th style='padding: 10px; text-align: left; color: #333; background-color: #e9e9e9; border: 1px solid #ddd; font-size: 12px;'>University</th>
            </tr>
          </thead>
          <tbody>
    `;

    universities.forEach((univ) => {
      html += `
        <tr>
          <td style='padding: 10px; color: #555; border: 1px solid #ddd; font-size: 13px;'>${univ.qs_ranking_year_2025}</td>
          <td style='padding: 10px; display: flex; align-items: center; color: #555; border: 1px solid #ddd; font-size: 13px;'>
            <img src="${univ.photo == null ? sampleUniversity : univ.photo }" width="24" height="24" style="border-radius: 50%; margin-right: 10px;" />
            ${univ.name}
          </td>
        </tr>
      `;
    });

    html += `
          </tbody>
        </table>
      </div>
      <br/>
    `;

    // Rating Table
    if (rating.length >= 3) {
      html += `
        <div style='padding: 10px;'>
          <table style='width: 100%; border-collapse: collapse;'>
            <thead>
              <tr>
                <th colspan="2" style='padding: 10px; text-align: left; color: #333; font-size: 16px; background-color: #f2f2f2;'>
                  Top Rated Universities
                </th>
              </tr>
              <tr>
                <th style='padding: 10px; text-align: left; color: #333; background-color: #e9e9e9; border: 1px solid #ddd; font-size:12px;'>University Name</th>
                <th style='padding: 10px; text-align: left; color: #333; background-color: #e9e9e9; border: 1px solid #ddd; font-size:12px;'>Rating</th>
              </tr>
            </thead>
            <tbody>
      `;

      rating.forEach((ratings, index) => {
        html += `
          <tr>
            <td style='padding: 10px; color: #555; border: 1px solid #ddd; font-size:12px;'>${ratings.college_name}</td>
            <td style='padding: 10px; color: #555; border: 1px solid #ddd; font-size:12px;'>${ratings.uni_rating}</td>
          </tr>
        `;
      });

      html += `
            </tbody>
          </table>
        </div>
        <br/>
      `;
    }

    // Courses Table
    html += `
      <div style='padding: 10px;'>
        <table style='width: 100%; border-collapse: collapse;'>
          <thead>
            <tr>
              <th colspan="2" style='padding: 10px; text-align: left; color: #333; font-size: 16px; background-color: #f2f2f2;'>
                Popular Courses
              </th>
            </tr>
            <tr>
              <th style='padding: 10px; text-align: left; color: #333; background-color: #e9e9e9; border: 1px solid #ddd; font-size: 14px;  '>No</th>
              <th style='padding: 10px; text-align: left; color: #333; background-color: #e9e9e9; border: 1px solid #ddd; font-size: 14px;'>Course Name</th>
            </tr>
          </thead>
          <tbody>
    `;

    courses.forEach((course, index) => {
      html += `
        <tr>
          <td style='padding: 10px; color: #555; border: 1px solid #ddd;'>${index + 1}</td>
          <td style='padding: 10px; color: #555; border: 1px solid #ddd;'>${course}</td>
        </tr>
      `;
    });

    html += `
          </tbody>
        </table>
      </div>
      <br/>
    `;

    return html;

    
  };

  const getCountryCode = (countryKey) => {
    const countryMap = {
      united_states: "US",
      united_kingdom: "GB",
      canada: "CA",
      australia: "AU",
      germany: "DE",
      ireland: "IE",
      new_zealand: "NZ",
      spain: "ES",
      france: "FR",
      singapore:"SG"
    };
    return countryMap[countryKey] || countryKey;
  };

  const getCountryName = (countryKey) => {
    const countryMap = {
      united_states: "US",
      united_kingdom: "UK",
      canada: "CA",
      australia: "AU",
      germany: "DE",
      ireland: "IE",
      new_zealand: "NZ",
      spain: "ES",
      france: "FR",
      singapore:"SG"
    };
    return countryMap[countryKey] || countryKey;
  };

  const getColorByCountry = (countryKey) => {
    const colorMap = {
      united_states: "#FF5733",
      united_kingdom: "#33FF57",
      canada: "#33F3A2",
      australia: "#F3A233",
      germany: "#3357FF",
      ireland: "#3357FF",
      new_zealand: "#3357FF",
      spain: "#3357FF",
      france: "#3357FF",
      singapore:"#3357FF"

    };
    return colorMap[countryKey] || "#CCCCCC";
  };

  const getFlagUrlByCountry = (countryKey) => {
    const flagMap = {
      united_states: usaImage,
      united_kingdom: ukImage,
      canada: canadaImage,
      australia: australiaImage,
      germany: germanyImage,
      ireland:irelandImage,
      new_zealand:nzImage,
      spain:spainImage,
      france:franceImage,
      singapore:singaporeImage,
    };
    return flagMap[countryKey] || "https://www.example.com/flags/default.png";
  };

  return (
    <div>
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>

      {/* Modal */}
      {modalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={hideModal}>&times;</span>
            <div dangerouslySetInnerHTML={{ __html: modalContent }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Charts;
