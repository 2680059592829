import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../assets/css/ReferralPage.module.css";
import "./MilestoneReferral.css";
import reward1Img from "../assets/images/countries/2.jpg";
import reward2Img from "../assets/images/countries/3.jpg";
import reward3Img from "../assets/images/countries/4.jpg";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const apiUrl = process.env.REACT_APP_API_URL;
const shareURL  = process.env.REACT_APP_SHARE_URL;

const milestones = [
  { level: 10, reward: "Reward 1", image: reward1Img },
  { level: 20, reward: "Reward 2", image: reward2Img },
  { level: 30, reward: "Reward 3", image: reward3Img },
];

const MilestoneReferral = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [totalReferrals, setTotalReferrals] = useState(0);

  const referralLink = `https://theindianstudent.com/#/home?referal_id=${localStorage.getItem(
    "email"
  )}`;

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}leaderboard`,
          { params: { campaign_id: 1 } },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const totalReferrals = response.data.data.total_referal || 0;
        setTotalReferrals(totalReferrals);
        setCurrentStep(totalReferrals);
      } catch (error) {
        console.error("Error fetching leaderboard data", error);
      }
    };

    fetchLeaderboard();
  }, []);

  // Calculate percentage for the progress bar
  const calculatePercent = () => {
    if (currentStep <= 10) {
      return (currentStep / 10) * 33.33; // First 10 steps cover the first 33.33% of the bar
    }
    if (currentStep <= 20) {
      return 33.33 + ((currentStep - 10) / 10) * 33.33; // Steps from 11 to 20 cover the next 33.33%
    }
    if (currentStep <= 30) {
      return 66.66 + ((currentStep - 20) / 10) * 33.33; // Steps from 21 to 30 cover the last 33.33%
    }

    return 100; // If the steps exceed 30, the progress bar is complete
  };

  // Function to copy referral link
  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    alert("Referral link copied to clipboard!");
  };

  return (
    <>
      <div className={styles.container}>
        <h1>Invite your friends to win rewards</h1>
        <p>Copy and share your referral link</p>

        {/* Referral Link Section */}
        <div className={styles.referralLinkSection}>
          <input
            type="text"
            value={referralLink}
            readOnly
            className={styles.referralInput}
          />
          <button className={styles.copyButton} onClick={copyReferralLink}>
            Copy
          </button>
        </div>

        {/* Social Share Icons */}
        <div className={styles.socialIcons}>
          {/* Add your social icons here */}
        </div>
      </div>
      <div>
        <section className="my-5">
          <div className="total-referrals">
            <p>Total Friends Joined: {totalReferrals}</p>
          </div>
          <br></br>
          <br></br>

          <div className="container">
            <main className="main-wrapper">
              <div
                className="steps-wrapper"
                style={{ marginTop: 35, marginBottom: 42 }}
              >
                <ProgressBar
                  percent={calculatePercent()}
                  filledBackground="gold" // Modern gradient colors
                  height={10} // Increased height for a thicker, modern bar
                  stepPositions={[0, 33.33, 66.66, 100]} // To align with the steps
                >
                  {/* Zero Progress Step (no reward image) */}
                  <Step transition="scale">
                    {({ accomplished }) => (
                      <div style={{ textAlign: "center" }}>
                        <div></div>
                      </div>
                    )}
                  </Step>

                  {/* Milestones (10, 20, 30) with rewards */}
                  {milestones.map((milestone, index) => (
                    <Step key={milestone.level} transition="scale">
                      {({ accomplished }) => (
                        <div
                          style={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{
                              filter: `grayscale(${
                                currentStep >= milestone.level ? 0 : 80
                              }%)`, // Accomplished if currentStep >= milestone.level
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%", // Rounded reward images
                              transition: "filter 0.3s ease",
                              boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.1)`, // Add a soft shadow for a modern look
                            }}
                            src={milestone.image}
                            alt={milestone.reward}
                          />
                          <div
                            style={{
                              marginTop: "10px", // Add spacing between image and reward text
                              fontWeight: "500", // Modern font weight
                              fontSize: "16px", // Adjusted font size for modern design
                              color:
                                currentStep >= milestone.level
                                  ? "#6a1b9a"
                                  : "#bbb",
                              transition: "color 0.3s ease", // Smooth transition for the text color
                            }}
                          >
                            {milestone.reward}
                          </div>
                        </div>
                      )}
                    </Step>
                  ))}
                </ProgressBar>
              </div>
            </main>
          </div>
        </section>
      </div>
    </>
  );
};

export default MilestoneReferral;
