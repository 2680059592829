import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faUser } from '@fortawesome/free-regular-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import hamburger from '../assets/images/hamburger.svg';
import { useNavigate } from 'react-router-dom';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

const token = localStorage.getItem("token");
let showReferalPage = localStorage.getItem("is_show_referal") == "show" ? true : false;

const Header = () => {
  const navigate = useNavigate();
  const handleReview = () => {
    navigate('/review'); 
  };

  const handleClick = () => {
    navigate('/login'); 
  };

  const handleReferal = () => {
    navigate('/referals'); 
  };

  const handleLogout = () => {
    // Clear localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('userData');
    localStorage.removeItem('name');
    localStorage.removeItem('isLogin');
    localStorage.removeItem('campaign_id');
    localStorage.removeItem('signupData');
    localStorage.removeItem('signupFormValues');
    localStorage.removeItem('is_show_referal');
    navigate('/login', { replace: true });
    window.location.reload();
};


  useEffect(() => {
    // Function to handle screen darkening
    const darkenScreen = (yesno) => {
      const screenDarken = document.querySelector(".screen-darken");
      if (yesno) {
        screenDarken.classList.add("active");
      } else {
        screenDarken.classList.remove("active");
      }
    };

    // Function to close offcanvas
    const closeOffcanvas = () => {
      darkenScreen(false);
      const offcanvas = document.querySelector(".mobile-offcanvas.show");
      if (offcanvas) {
        offcanvas.classList.remove("show");
      }
      document.body.classList.remove("offcanvas-active");
    };

    // Function to show offcanvas
    const showOffcanvas = (offcanvasId) => {
      darkenScreen(true);
      const offcanvas = document.getElementById(offcanvasId);
      if (offcanvas) {
        offcanvas.classList.add("show");
      }
      document.body.classList.add("offcanvas-active");
    };

    // Handle click events for offcanvas triggers
    document.querySelectorAll("[data-trigger]").forEach(element => {
      const offcanvasId = element.getAttribute("data-trigger");
      element.addEventListener("click", (e) => {
        e.preventDefault();
        showOffcanvas(offcanvasId);
      });
    });

    // Handle click events for close buttons
    document.querySelectorAll(".btn-close").forEach(button => {
      button.addEventListener("click", () => {
        closeOffcanvas();
      });
    });

    // Handle click events for screen darken
    document.querySelector(".screen-darken").addEventListener("click", () => {
      closeOffcanvas();
    });

    // Function to update navbar position
    const updateNavbar = () => {
      const navbarTop = document.getElementById("navbar_top");
      if (window.pageYOffset > 50) {
        navbarTop.classList.add("fixed-top");
        const navbarHeight = document.querySelector(".navbar").offsetHeight;
        document.body.style.paddingTop = `${navbarHeight}px`;
      } else {
        navbarTop.classList.remove("fixed-top");
        document.body.style.paddingTop = "0";
      }
    };

    // Initial check when the page loads
    updateNavbar();

    // Add scroll event listener
    window.addEventListener("scroll", updateNavbar);

    // Cleanup event listeners on component unmount
    return () => {
      document.querySelectorAll("[data-trigger]").forEach(element => {
        const offcanvasId = element.getAttribute("data-trigger");
        element.removeEventListener("click", showOffcanvas.bind(null, offcanvasId));
      });

      document.querySelectorAll(".btn-close").forEach(button => {
        button.removeEventListener("click", closeOffcanvas);
      });

      document.querySelector(".screen-darken").removeEventListener("click", closeOffcanvas);

      window.removeEventListener("scroll", updateNavbar);
    };
  }, []);

  return (
    <>
      <span className="screen-darken"></span>
      <nav id="navbar_top" className="mobile-offcanvas navbar navbar-expand-lg fixed-top bg-gray">
        <div className="container">
          <div className="offcanvas-header">
            <button className="btn-close float-end"></button>
          </div>
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item active">
              {/* <Link className="nav-link" to="/">Stories</Link> */}
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/review">Write A Review</Link>
            </li> */}

          </ul>

          {showReferalPage && (
            <button className="btn login-btn" type="button" onClick={handleReferal}>
              <FontAwesomeIcon icon={faArrowCircleRight} className="me-2" /> Referal
            </button>
          )}&nbsp;&nbsp;

         
            <button className="btn login-btn" type="submit" onClick={handleReview}>
              <FontAwesomeIcon icon={faEdit} className="me-2" /> Write a review 
            </button>
          &nbsp;&nbsp;

          {!token ? (
            <button className="btn login-btn" type="submit" onClick={handleClick}>
              <FontAwesomeIcon icon={faUser} className="me-2" /> Login
            </button>
          ):(
            <button className="btn login-btn" type="submit" onClick={handleLogout}>
              <FontAwesomeIcon icon={faUser} className="me-2" /> Logout
            </button>
          )}
        </div>
      </nav>

      <div className="mobile-menu d-flex d-sm-flex d-lg-none justify-content-between align-items-center">
        <Link className="navbar-brand d-flex" to="/">
          <img src={logo} alt="Logo" />
        </Link>

        <button data-trigger="navbar_top" type="button" className="mt-0 ms-3 hamburger">
          <img src={hamburger} alt="Menu" />
        </button>

          {/* <button className="btn login-btn" type="submit">
            <FontAwesomeIcon icon={faUser} className="me-2" /> Login
          </button> */}
      </div>
    </>
  );
};

export default Header;
