import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import sampleUniversity from "../assets/images/countries/11.jpg";
import axios from 'axios';
import slugify from 'slugify';


const UniversitySelect = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log(apiUrl);
  const [universities, setUniversities] = useState([]);

  useEffect(() => {
    // Fetch universities from the Laravel API
    axios.get(`${apiUrl}getHomeUniversityList`) // Ensure the URL is correct
      .then(response => {
        const formattedUniversities = response.data.map(university => ({
          value: university.id,
          label: university.name,
          country_name:university.country_name,
          city_name:university.city_name,
          about_us:university.about_us,
          image: university.photo == null ? sampleUniversity : university.photo, // Store image for custom rendering
          link: `#/universites/`+slugify(university.name, { lower: true, strict: true }) // Replace with your desired URL pattern
        }));
        setUniversities(formattedUniversities);
      })
      .catch(error => console.error('Error fetching university list:', error));
  }, [apiUrl]);

  const customOption = ({ image, label }) => (
    <div className="university-option">
      {image ? (
        <img
          src={image}
          alt={label}
          style={{ width: 30, height: 30}}
        />
      ) : (
        <div style={{ width: 30, height: 30}} /> // Placeholder for missing images
      )}
      &nbsp;&nbsp;{label}
    </div>
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left', // Align text to the left
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: 'left', // Align menu content to the left
    }),
    control: (provided) => ({
      ...provided,
      textAlign: 'left', // Align control content to the left
    }),
  };
  

  const handleSelectChange = selectedOption => {
    if (selectedOption && selectedOption.link) {
      if(selectedOption && selectedOption.about_us === null){
        let reviewData = {
          "country_of_study":selectedOption.country_name,
          "institute_name":selectedOption.label,
          "city_of_study":selectedOption.city_name
        };
        localStorage.setItem("signupFormValues",JSON.stringify(reviewData));
        window.location.href = "https://theindianstudent.com/#/review";
      }else{
        window.location.href = selectedOption.link;
      }
    }
  };


  return (
    <Select
      options={universities}
      formatOptionLabel={customOption} 
      onChange={handleSelectChange}
      placeholder="Review your University"
      className="form-control border-0"
      styles={customStyles}
    />
  );
};

export default UniversitySelect;
